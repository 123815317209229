<template>
  <section class="wood-materials-price-banner" v-if="hasPrices">
    <div class="banner d-flex flex-column flex-md-row-reverse">
      <img class="banner__image" src="@/assets/images/summary/wood-materials-price-banner.jpg" alt="banner" />
      <div
        class="banner__content d-flex justify-content-center align-items-center align-items-md-start flex-column text-center text-md-left"
      >
        <h2 class="banner__title">
          {{ t('banners.woodMaterialsPrice.title') }}
        </h2>
        <p class="banner__text banner__text--requirements">
          {{ t('banners.woodMaterialsPrice.requirements') }}
          <strong class="banner__requirements">{{ formState.results.wood_materials_requirement }} m³</strong>
          <br />
          {{ t('banners.woodMaterialsPrice.requirementsInfo') }}
        </p>
        <p class="banner__text">
          {{ t('banners.woodMaterialsPrice.description') }}
        </p>
        <div class="banner__price">
          <strong>
            {{ formState.results.wood_materials_price.min }} {{ t('general.currency') }} -
            <wbr />
            {{ formState.results.wood_materials_price.max }} {{ t('general.currency') }}
            <span>*</span>
          </strong>
        </div>
        <template v-if="formState.results?.wood_working_price?.min && formState.results?.wood_working_price?.max">
          <p class="banner__text">
            {{ t('banners.woodMaterialsPrice.woodworking') }}
          </p>
          <div class="banner__price">
            <strong>
              {{ formState.results.wood_working_price.min }} {{ t('general.currency') }} -
              <wbr />
              {{ formState.results.wood_working_price.max }} {{ t('general.currency') }}
              <span>*</span>
            </strong>
          </div>
        </template>
        <p class="banner__info">
          {{ t('banners.woodMaterialsPrice.info') }}
        </p>
        <p class="banner__text" v-html="t('banners.woodMaterialsPrice.requestOffer')" />
        <p class="banner__link">
          <a :href="t('banners.woodMaterialsPrice.cta.link')" target="_blank" class="btn btn-white">
            {{ t('banners.woodMaterialsPrice.cta.label') }}
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormState from '@/hooks/formState'

export default defineComponent({
  name: 'WoodMaterialsPriceBanner',
  setup() {
    const { formState } = useFormState()
    const { t } = useI18n()

    const hasPrices = formState.results?.wood_materials_price?.min && formState.results?.wood_materials_price?.max

    return {
      formState,
      hasPrices,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.wood-materials-price-banner {
  background-color: $dark-blue;
  color: $white;
  margin-top: 65px;

  @media (min-width: 768px) {
    margin: 75px 0;
  }
}

.banner {
  &__content {
    padding: 20px 20px 40px;

    @media (min-width: 768px) {
      padding: 32px 30px;
    }
  }

  &__title {
    color: $light-blue;
    font-size: 24px;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    margin-bottom: 10px;

    &--requirements {
      margin-bottom: 20px;
    }
  }

  &__requirements {
    font-weight: bold;
    white-space: nowrap;
    font-size: 18px;
  }

  &__price {
    font-size: 30px;
    white-space: nowrap;
    line-height: 1.25;
    margin-bottom: 10px;

    @media (min-width: 992px) {
      font-size: 36px;
      margin-bottom: 5px;
    }
  }

  &__info {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__link {
    margin-bottom: 0;
  }

  &__image {
    object-fit: cover;

    @media (max-width: 767px) {
      max-height: 200px;
    }

    @media (min-width: 768px) {
      flex: 0 0 360px;
      max-width: 360px;
    }
  }
}
</style>
